<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('customer.edit','编辑客户'):$l('customer.add','新增客户')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('customer.labelWidth','80px')">
      <el-form-item :label="$l('customer.name','客户名称')" prop="name" :rules="$rule.notNull">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item :label="$l('user.contactPerson','联系人')" prop="contactName">
        <el-input v-model="model.contactName" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item :label="$l('user.phone','联系电话')" prop="contactPhone">
        <el-input v-model="model.contactPhone" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item :label="$l('address.area','区域')" prop="districtCode" :rules="$rule.notNull">
        <vm-district :area-code.sync="model.districtCode"></vm-district>
      </el-form-item>
      <el-form-item :label="$l('address.address','地址')" prop="address" :rules="$rule.notNull">
        <el-input v-model="model.address" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import VmDistrict from "@/components/VmDistrictCascader";

  export default {
    components: {VmDistrict},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        roles: [],
        checkedRoleIds: [],
        model: {
          id: 0,
          name: "",
          contact: "",
          phone: "",
          districtCode: "",
          address: "",
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        console.log(this.model.id);
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`customers/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("customers", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
