<template>
  <div class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="customers">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l("common.add", "新增")}}</el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('customer.name','客户名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.contactPerson','联系人')">
            <el-input v-model.trim="filter.contactName" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.phone','手机号')">
            <el-input v-model.trim="filter.contactPhone" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('address.area','区域')">
            <vm-district-select
              :province-code.sync="filter.provinceCode"
              :city-code.sync="filter.cityCode"
              :area-code.sync="filter.areaCode">
            </vm-district-select>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$l('customer.name','客户名称')"></el-table-column>
      <el-table-column prop="contactName" :label="$l('user.contactPerson','联系人')"></el-table-column>
      <el-table-column prop="contactPhone" :label="$l('user.phone','手机号')" align="center"></el-table-column>
      <el-table-column prop="projectQuantity" label="项目数量" align="center"></el-table-column>
      <el-table-column :label="$l('address.area','区域')" align="center">
        <template slot-scope="scope">
          {{scope.row.province + "-" + scope.row.city + "-" + scope.row.area}}
        </template>
      </el-table-column>
      <el-table-column prop="address" :label="$l('address.address','地址')" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./CustomerEdit.vue";

  export default {
    components: {EditPage},
    data() {
      return {
        filter: {
          name: "",
          contactName: "",
          contactPhone: "",
          provinceCode: "",
          cityCode: "",
          areaCode: "",
        },
      };
    },
    mounted() {
      this.getList(1);

    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("customers", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
